<template>
  <div>
    <!-- begin: Extended/Export Detailed Report -->
    <div class="d-flex justify-content-end align-content-center">
      <div class="my-auto">
        <b-button
          class="font-weight-bolder"
          variant="light-primary"
          @click="$router.push({ name: 'employeeYearlyAttendanceExport' })"
          >Details
        </b-button>
      </div>
    </div>
    <!-- end: Extended/Export Detailed Report -->

    <!-- begin: Yearly Table    -->
    <EmployeeYearlyAttendanceTable
      :items="items"
      :pagination="pagination"
      :is-busy="isBusyStore"
      @changePage="handlePageChange"
      @showIndividualAttendance="goToYearlyIndividual"
      @away-count-clicked="showAwayModal"
      @leave-count-clicked="showLeaveModal"
    ></EmployeeYearlyAttendanceTable>
    <!-- end: Yearly Table    -->

    <!-- begin: Away    -->
    <EmployeeIndividualAwayModal
      :aways="aways"
      :is-busy="isBusy"
      :pagination="awayPagination"
      @page-change="handleAwayChange"
    ></EmployeeIndividualAwayModal>
    <!-- end: Away    -->

    <EmployeeIndividualLeaveModal
      :leaves="leaves"
      :is-busy="isBusy"
      :pagination="leavePagination"
      @page-change="handleLeavePage"
    ></EmployeeIndividualLeaveModal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import EmployeeYearlyAttendanceTable from "@/modules/company/components/attendance/yearly/EmployeeYearlyAttendanceTable";
import EmployeeIndividualAwayModal from "@/modules/company/components/attendance/common/EmployeeIndividualAwayModal";
import EmployeeIndividualLeaveModal from "@/modules/company/components/attendance/common/EmployeeIndividualLeaveModal";
export default {
  name: "EmployeeYearlyAttendance",
  components: {
    EmployeeIndividualLeaveModal,
    EmployeeIndividualAwayModal,
    EmployeeYearlyAttendanceTable,
  },
  data() {
    return {
      isBusy: false,
      employee: {},
      date: new Date(dayjs().format()),
      year: dayjs().year(),
      attendance: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeYearlyAttendanceList")
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setEmployeeYearlyAttendancePage", page);
      this.fetch();
    },

    goToYearlyIndividual(data) {
      console.log(data);
      this.$router.push({
        name: "individualYearlyAttendance",
        query: {
          id: data.item.id,
          name: data.item.name,
          year: dayjs(this.dateSelected).year(),
        },
      });
    },

    /**
     * Show employee leave modal.
     *
     * @param data
     */
    showAwayModal(data) {
      console.log(data);
      console.log("Date filter is", this.date);

      this.$store.dispatch("changeIndividualEmployeeAwayListFilter", {
        date: this.date,
        employeeId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeAwayYearlyList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-away-modal");
    },

    /**
     *
     */
    handleAwayChange(page) {
      console.log("Page : ", page);

      this.$store.commit("setIndividualEmployeeAwayListPage", page);
      this.$store.dispatch("fetchIndividualEmployeeAwayYearlyList");
    },

    /**
     * Show school employee leave modal.
     *
     * @param data
     */
    showLeaveModal(data) {
      console.log(data);

      this.$store.dispatch("changeIndividualEmployeeLeaveListFilter", {
        date: this.date,
        employeeId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeLeaveYearlyList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-leave-modal");
    },

    /**
     * Handle teacher individual leave page change event.
     *
     * @param page
     */
    handleLeavePage(page) {
      console.log(page);

      this.$store.commit("setIndividualEmployeeLeaveListPage", page);
      this.$store.dispatch("fetchIndividualEmployeeLeaveYearlyList");
    },

    navigatePrint() {
      this.fetchCompany();
      this.$router.push({ name: "employeeYearlyAttendancePdf" });
    },

    fetchCompany() {
      this.$store.dispatch("fetchCompanyProfileForm").then(() => {});
    },
  },
  computed: {
    ...mapGetters({
      items: "getEmployeeYearlyAttendanceList",
      pagination: "getEmployeeYearlyAttendancePagination",
      leaves: "getIndividualEmployeeLeaveList",
      aways: "getIndividualEmployeeAwayList",
      leavePagination: "getIndividualEmployeeLeaveListPagination",
      awayPagination: "getIndividualEmployeeAwayListPagination",
      dateSelected: "getEmployeeYearlyAttendanceListDate",
      isBusyStore: "getEmployeeYearlyAttendanceListBusy",
    }),
  },
};
</script>

<style scoped></style>
